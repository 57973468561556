import { ChangeDetectionStrategy, Component, computed, ElementRef, input, signal, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UtilFormattingModule } from '@oper-client/shared/util-formatting';
import { UiModule } from '../../ui.module';
import { ChartItem } from '../../interfaces/chart-item';

interface Position {
	x: number;
	y: number;
}
interface Size {
	width: number;
	height: number;
}
@Component({
	selector: 'oper-client-stacked-barchart',
	templateUrl: './stacked-barchart.html',
	styleUrls: ['./stacked-barchart.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [UtilFormattingModule, CommonModule, TranslateModule, UiModule],
})
export class StackedBarchartComponent {
	@ViewChild('toolTip') public tooltip: ElementRef;
	@ViewChild('container') public container: ElementRef;

	readonly items = input.required<ChartItem[]>();
	readonly groupByColor = input<boolean>(false);
	readonly isStacked = input<boolean>(true);
	readonly isToolTipActive = input<boolean>(false);

	indexOfHoveredItem = signal<number>(-1);
	mousePosition = signal<Position>({ x: 0, y: 0 });
	tooltipPosition = signal<Position>({ x: 0, y: 0 });
	tooltipSize = signal<Size>({ width: 0, height: 0 });

	readonly total = computed(
		() =>
			+this.items()
				.reduce((acc, item) => acc + item.value, 0)
				.toFixed(2)
	);

	readonly itemsFormatted = computed(() => {
		if (this.groupByColor()) {
			const groupedObj = this.groupItemsPerColors(this.items());
			return this.returnReducedArrayFromGroup(groupedObj).sort((a, b) => b.value - a.value);
		} else {
			return this.items()
				.filter((item) => item.value > 0)
				.sort((a, b) => b.value - a.value);
		}
	});

	readonly itemsLegend = computed(() => {
		return this.items()
			.filter((item) => item.value > 0)
			.sort((a, b) => b.value - a.value);
	});

	groupItemsPerColors(items: ChartItem[]): object {
		return items
			.filter((item) => item.value > 0)
			.reduce(
				(prev, current) => ({
					...prev,
					[current.color]: [...(prev[current.color] || []), current],
				}),
				{}
			);
	}

	returnReducedArrayFromGroup(itemsGroupedByColor: object): ChartItem[] {
		const result: ChartItem[] = [];

		for (const color in itemsGroupedByColor) {
			const items = itemsGroupedByColor[color];
			const sum = items.reduce((acc, item) => acc + item.value, 0);

			// Create a new object with the summed value
			const summedItem: ChartItem = {
				label: items.length > 1 ? items[0].groupName : items[0].label,
				value: sum,
				color: color,
				key: items[0].key,
				hide: items[0].hide,
			};
			result.push(summedItem);
		}
		return result;
	}

	trackMouse(event: MouseEvent) {
		this.mousePosition.set({
			x: event.clientX,
			y: event.clientY,
		});

		this.setTooltipPosition(this.mousePosition());
	}

	manageTooltip(index: number, event: MouseEvent): void {
		if (!this.isToolTipActive()) return;
		this.indexOfHoveredItem.set(index);

		this.setTooltipPosition(this.mousePosition());
	}

	setTooltipPosition(mousePos: Position) {
		const heightBuffer = 8;
		setTimeout(() => {
			const containerRect = this.container.nativeElement?.getBoundingClientRect();
			if (!containerRect) {
				return;
			}
			this.tooltipSize.set({
				width: this.tooltip.nativeElement.offsetWidth,
				height: this.tooltip.nativeElement.offsetHeight + heightBuffer,
			});
			const x = mousePos.x - containerRect.left - this.tooltipSize().width / 2;
			const y = mousePos.y - containerRect.top;

			this.tooltipPosition.set({ x, y });
		}, 0);
	}
}
