import { ChangeDetectorRef, TemplateRef } from '@angular/core';

import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { ContextMenuGroup, ContextMenuItem } from '@oper-client/shared/data-model';
import { SafeStyle } from '@angular/platform-browser';

/**
 * Example usage
 * =============
 *
 * <button #target>Target</button>
 * <oper-client-context-menu
 * 		[target]="target"
 * 		[items]="items"
 *	></oper-client-context-menu>
 */

@Component({
	selector: 'oper-client-context-menu',
	templateUrl: './context-menu.component.html',
	styleUrls: ['./context-menu.component.scss'],
})
export class ContextMenuComponent implements OnInit, OnDestroy {
	private targetClickListener: any;
	private scrollTimeOut: any;

	@Input() target: ElementRef<HTMLElement>;
	@Input() items: ContextMenuItem[];
	@Input() groups: ContextMenuGroup[];
	@Input() skipPositionList: boolean;
	@Input() customTemplate?: TemplateRef<any>;

	@Output() itemClicked: EventEmitter<ContextMenuItem> = new EventEmitter();
	@Output() groupCtaClicked: EventEmitter<ContextMenuGroup> = new EventEmitter();
	@Output() showEvent = new EventEmitter<void>();
	@Output() hideEvent = new EventEmitter<void>();

	@HostBinding('class.oper-client-context-menu--positioned') positioned: boolean;
	@HostBinding('class.oper-client-context-menu--shown') shown: boolean;
	@HostBinding('style.top') top: SafeStyle;
	@HostBinding('style.left') left: SafeStyle;

	/** Repositions the context menu when the window is scrolled */
	@HostListener('window:resize', ['$event'])
	@HostListener('window:scroll', ['$event'])
	private handleReposition(): void {
		if (this.shown) {
			this.showAndPosition();
		}
	}

	/** Hides the context menu when a click happens outside the target and context menu */
	@HostListener('document:click', ['$event']) private onDocumentClick(event: MouseEvent): void {
		if (!(this.target as any).contains(event.target) && !this.elementRef.nativeElement.contains(event.target)) {
			this.hide();
		}
	}

	/** Hides the context menu when it’s open and the Escape key is pressed */
	@HostListener('document:keydown', ['$event']) private onKeyDown(event: KeyboardEvent): void {
		if (event.key === 'Escape' && this.shown) {
			this.hide();
		}
	}

	constructor(
		private renderer: Renderer2,
		private elementRef: ElementRef,
		private changeDetectorRef: ChangeDetectorRef
	) {}

	ngOnInit() {
		/** Shows and positions the context menu when the target is clicked */
		this.targetClickListener = this.renderer.listen(this.target, 'click', () => {
			if (!this.shown) {
				this.showAndPosition();
			} else {
				this.hide();
			}
		});
	}

	/**
	 * Shows and positions the context menu under or above the target
	 *
	 * TODO: This is legacy code and we have to fix this nested setTimeout hell! @zoltanradics
	 */
	showAndPosition() {
		this.shown = false;
		this.positioned = false;

		this.clearScrollTimeout();
		this.scrollTimeOut = setTimeout(() => {
			const offset = (this.target as any).getBoundingClientRect();

			// Place below target
			if (typeof this.skipPositionList === 'undefined') {
				this.top = `${(offset.top + offset.height) / 10}rem`;
			}

			// Align with left side of target, keeping into account left margin of list
			this.left = `calc(${offset.left / 10}rem - .5rem)`;

			// Positions but doesn't show menu yet
			this.positioned = true;
			this.changeDetectorRef.markForCheck();

			// Wait until dropdown is added to the DOM
			setTimeout(() => {
				const elementRefOffset = this.elementRef.nativeElement.getBoundingClientRect();

				// Place above target if there wasn't enough space below the target
				if (window.innerHeight < elementRefOffset.height + elementRefOffset.top && typeof this.skipPositionList === 'undefined') {
					this.top = `${(offset.top - elementRefOffset.height) / 10}rem`;
				}

				// Align to the right side of the window if the context menu is cut off horizontally
				if (window.innerWidth < elementRefOffset.width + elementRefOffset.left) {
					this.left = `${(window.innerWidth - elementRefOffset.width) / 10}rem`;
				}

				// Ok, we're good. Show the menu!
				this.shown = true;
				this.changeDetectorRef.markForCheck();
				this.showEvent.emit();
			});
		}, 100);
	}

	/** Hides the context menu */
	hide() {
		if (this.shown) {
			this.hideEvent.emit();
		}
		this.positioned = false;
		this.shown = false;
	}

	/** Handles an item click */
	handleItemClick(item: ContextMenuItem) {
		// Emit an event
		this.itemClicked.emit(item);

		// Hide the context menu
		this.hide();
	}

	handleGroupClick(item: ContextMenuGroup) {
		this.groupCtaClicked.emit(item);

		// Hide the context menu
		this.hide();
	}

	/** Clears the scroll timeout */
	private clearScrollTimeout() {
		if (this.scrollTimeOut) {
			clearTimeout(this.scrollTimeOut);
		}
	}

	/** When the component is destroyed */
	ngOnDestroy() {
		// Unsubscribe the target click listeners
		this.targetClickListener();

		// Clear the scroll timeout
		this.clearScrollTimeout();
	}
}
