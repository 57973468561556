import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureFlagService } from '../services/feature-flag.service';
import { combineLatest, map, Observable, of, Subscription } from 'rxjs';
import { FeatureFlag } from '@oper-client/shared/data-model';

/**
 * Structural directive that conditionally renders content based on feature flag state(s).
 *
 * @example Basic usage with single flag:
 * ```html
 * <div *operClientIsFeatureEnabled="'MY_FEATURE'">
 *   Feature enabled content here
 * </div>
 * ```
 *
 * @example With else template:
 * ```html
 * <div *operClientIsFeatureEnabled="'MY_FEATURE'; else disabledTpl">
 *   Feature enabled content
 * </div>
 * <ng-template #disabledTpl>
 *   Feature disabled fallback content
 * </ng-template>
 * ```
 *
 * @example Multiple flags (all must be enabled):
 * ```html
 * <div *operClientIsFeatureEnabled="['FEATURE_1', 'FEATURE_2']">
 *   Only shown when all features are enabled
 * </div>
 * ```
 */
@Directive({
	selector: '[operClientIsFeatureEnabled]',
	standalone: true,
})
export class IsFeatureEnabledDirective implements OnInit, OnDestroy {
	private subscription: Subscription;

	/**
	 * Accepts a single flag key (string) or an array of flag keys.
	 * When multiple keys are provided, the content is rendered only if all flags are enabled.
	 */
	@Input('operClientIsFeatureEnabled') flag: FeatureFlag | FeatureFlag[];

	/**
	 * Optional else template reference to show when the flag(s) are disabled.
	 */
	@Input() operClientIsFeatureEnabledElse: TemplateRef<any>;

	constructor(
		private readonly featureFlagService: FeatureFlagService,
		private readonly templateRef: TemplateRef<any>,
		private readonly viewContainer: ViewContainerRef,
		private readonly cdr: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		// Create an observable that determines if the flag(s) are enabled.
		let flagObservable: Observable<boolean>;

		if (Array.isArray(this.flag)) {
			// When an array is provided, check that all flags are enabled.
			const observables = this.flag.map((flagKey) => this.featureFlagService.hasFeatureFlag(flagKey));
			flagObservable = combineLatest(observables).pipe(map((results) => results.every((isEnabled) => isEnabled)));
		} else if (typeof this.flag === 'string') {
			flagObservable = this.featureFlagService.hasFeatureFlag(this.flag);
		} else {
			// Fallback to a false observable if flag input is invalid.
			flagObservable = of(false);
		}

		this.subscription = flagObservable.subscribe((isEnabled) => {
			this.updateView(isEnabled);
		});
	}

	private updateView(isEnabled: boolean): void {
		this.viewContainer.clear();

		if (isEnabled) {
			// Render the main template if enabled.
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else if (this.operClientIsFeatureEnabledElse) {
			// Otherwise, render the fallback template if provided.
			this.viewContainer.createEmbeddedView(this.operClientIsFeatureEnabledElse);
		}
		// Trigger change detection (useful when using OnPush)
		this.cdr.markForCheck();
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}
