import { FinancialPlanLoanAmountTypeEnum } from '../constants/financial-plan-loan-amount-type.constants';
import { FinancialPlanOwnAmountFundEnum } from '../constants/financial-plan-own-amount-type.constant';
import { ProductTypeEnum } from '../constants/product-type.constants';
import { AnyObject } from './any-object.model';
import { FieldConfigExtended, LoanAmountFieldConfig, OwnFundFieldConfig } from './financial-plan-config.model';

export const FinancialPlanLoanAmountTypeToProductTypeMap: Record<FinancialPlanLoanAmountTypeEnum, ProductTypeEnum[]> = {
	[FinancialPlanLoanAmountTypeEnum.BRIDGE_LOAN]: [ProductTypeEnum.BRIDGE_LOAN],
	[FinancialPlanLoanAmountTypeEnum.MARKET_MORTGAGE_LOAN]: [
		ProductTypeEnum.MORTGAGE_LOAN,
		ProductTypeEnum.CONSUMER_FRIENDLY_LOAN,
		ProductTypeEnum.FREE_PURPOSE_LOAN,
	],
	[FinancialPlanLoanAmountTypeEnum.FREE_PURPOSE_LOAN]: [ProductTypeEnum.FREE_PURPOSE_LOAN],
	[FinancialPlanLoanAmountTypeEnum.CSOK_MORTGAGE_LOAN]: [ProductTypeEnum.CSOK_MORTGAGE_LOAN],
	[FinancialPlanLoanAmountTypeEnum.VILLAGE_CSOK_MORTGAGE_LOAN]: [ProductTypeEnum.VILLAGE_CSOK_MORTGAGE_LOAN],
	[FinancialPlanLoanAmountTypeEnum.BABY_FREE_PURPOSE_LOAN]: [ProductTypeEnum.BABY_LOAN],
	[FinancialPlanLoanAmountTypeEnum.CASH_LOAN]: [ProductTypeEnum.CASH_LOAN],
};

export const FinancialPlanOwnAmountTypeToProductTypeMap: Record<FinancialPlanOwnAmountFundEnum, ProductTypeEnum[]> = {
	[FinancialPlanOwnAmountFundEnum.VILLAGE_CSOK_CASH_LOAN]: [ProductTypeEnum.VILLAGE_CSOK_CASH_LOAN],
};

export interface FinancialPlanField {
	id?: number;
	created?: string;
	amount?: number;
	isOverridden?: boolean;
	loanRequest?: number;
}
export interface LoanAmountField extends FinancialPlanField {
	financialPlanLoanAmountField: LoanAmountFieldConfig;
}

export interface OwnFundField extends FinancialPlanField {
	financialPlanOwnFundField: OwnFundFieldConfig;
}

export interface FinancialPlanItem {
	field: FieldConfigExtended;
	amount: number;
	isOverridden: boolean;
}

export interface FinancialPlanLoanAmountItem {
	financialPlanLoanAmountField: LoanAmountFieldConfig;
	amount: number;
	isOverridden: boolean;
	id?: number;
}

export interface FinancialPlanOwnFundItem {
	financialPlanOwnFundField: OwnFundFieldConfig;
	amount: number;
	isOverridden: boolean;
	id?: number;
}

export interface FinancialPlanFieldUpdate extends Partial<FinancialPlanLoanAmountItem>, Partial<FinancialPlanOwnFundItem> {
	key: string;
}

export interface ExternalCalculatorValue {
	input: AnyObject<number>;
	output: AnyObject<number>;
	timestamp?: string;
}

export interface ExternalCalculators {
	[key: string]: ExternalCalculatorValue;
}

export interface FinancialPlan {
	financialPlanLoanAmountItems: FinancialPlanLoanAmountItem[];
	financialPlanOwnFundItems: FinancialPlanOwnFundItem[];
}

export interface MonthlyPaymentEstimation {
	item: FinancialPlanLoanAmountItem;
	rate: number;
	duration: number;
	monthlyInstallment: number;
}

export interface FinancialPlanCostItem {
	key: string;
	label: string;
	helpText: string;
	value: number;
	costType: string;
	costGroupType: string;
}

export interface FinancialPlanCostItemGroup {
	costGroupType: string;
	label?: string;
	costs: FinancialPlanCostItem[];
	totalCosts: number;
}
