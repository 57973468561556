import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { UiModule } from '../../ui.module';
import { ChartItem } from '@oper-client/shared/data-model';
import { StackedBarchartComponent } from '../stacked-barchart/stacked-barchart.component';

@Component({
	selector: 'oper-client-simulation-report-mortgage-overview',
	standalone: true,
	imports: [StackedBarchartComponent, TranslateModule, UiModule],
	templateUrl: './simulation-report-mortgage-overview.component.html',
	styleUrl: './simulation-report-mortgage-overview.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimulationReportMortgageOverviewComponent {
	readonly items = input<ChartItem[]>([]);
	readonly isRateLocked = input<boolean>(false);
	readonly newSimulationClick = output<void>();
}
