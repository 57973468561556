import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, of, switchMap } from 'rxjs';
import { catchError, mergeMap, takeUntil } from 'rxjs/operators';
import * as LoanApplicationActions from './loan-application.actions';
import {
	ADVISOR_SIMULATOR_SERVICE,
	CLIENT_SERVICE,
	IAdvisorSimulatorService,
	IClientService,
	ILoanRequestService,
	INCOME_SERVICE,
	IOfferService,
	IRealtyService,
	LIABILITY_SERVICE,
	LOAN_REQUEST_SERVICE,
	LoanRequestSubentityService,
	OFFER_SERVICE,
	REALTY_OWNER_SERVICE,
} from '@oper-client/shared/data-access';
import { cancelPendingHttpRequests } from '@oper-client/shared/app/data-access-app';
import { Income, Liability } from '@oper-client/shared/data-model';

@Injectable()
export class LoanApplicationEffects {
	readonly loadLoanRequest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanApplicationActions.loadLoanRequest),
			switchMap(({ loanRequestId }) =>
				this.loanRequestService.getLoanRequest(loanRequestId).pipe(
					map((loanRequest) => LoanApplicationActions.loadLoanRequestSuccess({ loanRequest })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(LoanApplicationActions.loadLoanRequestFailure({ error })))
				)
			)
		)
	);

	readonly updateLoanRequest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanApplicationActions.updateLoanRequest),
			switchMap(({ update }) =>
				this.loanRequestService.updateLoanRequest(update.id as number, update.changes).pipe(
					map((loanRequest) => LoanApplicationActions.updateLoanRequestSuccess({ loanRequest })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(LoanApplicationActions.updateLoanRequestFailure({ error })))
				)
			)
		)
	);

	readonly loadSimulation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanApplicationActions.loadSimulation),
			switchMap(({ simulationId }) =>
				this.simulatorService.loadSimulation(simulationId).pipe(
					map((simulation) => LoanApplicationActions.loadSimulationSuccess({ simulation })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(LoanApplicationActions.loadSimulationFailure({ error })))
				)
			)
		)
	);

	readonly loadOffers$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanApplicationActions.loadOffers),
			switchMap(({ loanRequestId }) =>
				this.offerService.getAll(loanRequestId).pipe(
					map((offers) => LoanApplicationActions.loadOffersSuccess({ offers })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(LoanApplicationActions.loadOffersFailure({ error })))
				)
			)
		)
	);

	readonly loadClients$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanApplicationActions.loadClients),
			switchMap(({ loanRequestId }) =>
				this.clientService.getAll(loanRequestId).pipe(
					map((clients) => LoanApplicationActions.loadClientsSuccess({ clients })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(LoanApplicationActions.loadClientsFailure({ error })))
				)
			)
		)
	);

	readonly loadRealties$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanApplicationActions.loadRealties),
			switchMap(({ loanRequestId }) =>
				this.realtyService.getAll(loanRequestId).pipe(
					map((realties) => LoanApplicationActions.loadRealtiesSuccess({ realties })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(LoanApplicationActions.loadRealtiesFailure({ error })))
				)
			)
		)
	);

	readonly updateRealty$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanApplicationActions.updateRealty),
			switchMap(({ loanRequestId, realty }) =>
				this.realtyService.update(loanRequestId, realty.id as number, realty.changes).pipe(
					map((realty) => LoanApplicationActions.updateRealtySuccess({ realty })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(LoanApplicationActions.updateRealtyFailure({ error })))
				)
			)
		)
	);

	readonly updateCollateralRealty$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanApplicationActions.updateCollateralRealty),
			switchMap(({ loanRequestId, realty }) =>
				this.realtyService.update(loanRequestId, realty.id as number, realty.changes).pipe(
					map((realty) => LoanApplicationActions.updateCollateralRealtySuccess({ realty })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(LoanApplicationActions.updateCollateralRealtyFailure({ error })))
				)
			)
		)
	);

	readonly loadClientIncomes$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanApplicationActions.loadClientIncomes),
			mergeMap(({ loanRequestId, clientId }) =>
				this.incomeService.getAll(loanRequestId, clientId).pipe(
					map((incomes) => LoanApplicationActions.loadClientIncomesSuccess({ incomes })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(LoanApplicationActions.loadClientIncomesFailure({ error })))
				)
			)
		)
	);

	readonly updateOwners$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanApplicationActions.updateOwners),
			switchMap(({ loanRequestId, realtyId, owners }) =>
				this.realtyService.updateOwnership(loanRequestId, realtyId, owners).pipe(
					map((owners) => LoanApplicationActions.updateOwnersSuccess({ owners })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(LoanApplicationActions.updateOwnersFailure({ error })))
				)
			)
		)
	);

	readonly loadClientLiabilities$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanApplicationActions.loadClientLiabilities),
			mergeMap(({ loanRequestId, clientId }) =>
				this.liabilityService.getAll(loanRequestId, clientId).pipe(
					map((liabilities) => LoanApplicationActions.loadClientLiabilitiesSuccess({ liabilities })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(LoanApplicationActions.loadClientLiabilitiesFailure({ error })))
				)
			)
		)
	);

	readonly createLoanRequestComment$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanApplicationActions.createLoanRequestComment),
			switchMap(({ loanRequestId, comment }) =>
				this.loanRequestService.createComment(loanRequestId, comment).pipe(
					map((comment) => LoanApplicationActions.createLoanRequestCommentSuccess({ comment })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(LoanApplicationActions.createLoanRequestCommentFailure({ error })))
				)
			)
		)
	);

	readonly updateClient$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanApplicationActions.updateClient),
			mergeMap(({ loanRequestId, client }) =>
				this.clientService.update(loanRequestId, client.id as number, client.changes).pipe(
					map((client) => LoanApplicationActions.updateClientSuccess({ client })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(LoanApplicationActions.updateClientFailure({ error })))
				)
			)
		)
	);

	readonly updateClientLiability$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanApplicationActions.updateClientLiability),
			mergeMap(({ loanRequestId, clientId, liability }) =>
				this.liabilityService.update(loanRequestId, clientId, liability.id as number, liability).pipe(
					map((liability) => LoanApplicationActions.updateClientLiabilitySuccess({ liability })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(LoanApplicationActions.updateClientLiabilityFailure({ error })))
				)
			)
		)
	);

	readonly updateClientIncome$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoanApplicationActions.updateClientIncome),
			mergeMap(({ loanRequestId, clientId, income }) =>
				this.incomeService.update(loanRequestId, clientId, income.id as number, income).pipe(
					map((income) => LoanApplicationActions.updateClientIncomeSuccess({ income })),
					takeUntil(this.actions$.pipe(ofType(cancelPendingHttpRequests))),
					catchError((error) => of(LoanApplicationActions.updateClientIncomeFailure({ error })))
				)
			)
		)
	);

	constructor(
		private actions$: Actions,
		@Inject(LOAN_REQUEST_SERVICE) private readonly loanRequestService: ILoanRequestService,
		@Inject(OFFER_SERVICE) private readonly offerService: IOfferService,
		@Inject(CLIENT_SERVICE) private readonly clientService: IClientService,
		@Inject(REALTY_OWNER_SERVICE) private realtyService: IRealtyService,
		@Inject(INCOME_SERVICE) private readonly incomeService: LoanRequestSubentityService<Income>,
		@Inject(LIABILITY_SERVICE) private readonly liabilityService: LoanRequestSubentityService<Liability>,
		@Inject(ADVISOR_SIMULATOR_SERVICE) private readonly simulatorService: IAdvisorSimulatorService
	) {}
}
