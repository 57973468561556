import { KeycloakOptions, KeycloakService } from 'keycloak-angular';
import { AuthType, CustomerInsights } from '../config.tokens';

export function getKeycloakOptions(config: CustomerInsights): KeycloakOptions {
	return {
		config: {
			url: config.kcAuth.url,
			realm: config.kcAuth.realm,
			clientId: config.kcAuth.clientId,
		},
		initOptions: {
			flow: 'standard',
			onLoad: 'check-sso',
			checkLoginIframe: false,
		},
		bearerExcludedUrls: [
			`/version.json`,
			`./assets/`,
			`${config?.serverUrl}/contact/`,
			`${config?.serverUrl}/resources/language/`,
			`${config?.serverUrl}/resources/features/`,
		],
	};
}

export function initializeKeycloak(keycloak: KeycloakService, config: CustomerInsights) {
	return () => {
		// Check if Keycloak SSO is enabled based on the configuration.
		if (config.authType === AuthType.BASIC_WITH_KEYCLOAK_SSO || config.authType === AuthType.KEYCLOAK_SSO) {
			return keycloak
				.init(getKeycloakOptions(config))
				.then((isAuthenticated) => {
					return isAuthenticated;
				})
				.catch(() => {
					return false;
				});
		}
		// If Keycloak is not used, simply resolve the promise immediately.
		return Promise.resolve();
	};
}
