@if (customTemplate) {
	<ng-container *ngTemplateOutlet="customTemplate"></ng-container>
} @else {
	<div class="menu">
		@if (items) {
			<ul class="list">
				@for (item of items; track $index) {
					<li class="list-item" [class.list-item--danger]="item.type === 'danger'">
						<button
							class="list-button"
							(click)="handleItemClick(item)"
							[disabled]="item.disabled"
							[attr.data-test]="item.dataAttr"
						>
							@if (item.icon) {
								<oper-client-fontawesome-icon class="list-button__icon" [icon]="item.icon" />
							}
							<span class="list-button-label">{{ item.label | translate }}</span>
						</button>
					</li>
				}
			</ul>
		}

		@for (group of groups; track $index) {
			<div class="group-header">
				<span class="group-header__label">{{ group.label | translate }}</span>
				@if (group.ctaLinkLabel) {
					<a class="cta-button-link" (click)="handleGroupClick(group)">{{ group.ctaLinkLabel | translate }}</a>
				}
			</div>

			@if (group.items) {
				<ul class="list">
					@for (item of group.items; track $index) {
						<li class="list-item" [class.list-item--danger]="item.type === 'danger'">
							<button
								class="list-button"
								(click)="handleItemClick(item)"
								[disabled]="item.disabled"
								[attr.data-test]="'proofTable--contextMenuItem--' + item.name"
							>
								@if (item.icon) {
									<oper-client-fontawesome-icon class="list-button__icon" [icon]="item.icon" />
								}
								<span class="list-button-label">{{ item.label | translate }}</span>
							</button>
						</li>
					}
				</ul>
			}
		}
	</div>
}
