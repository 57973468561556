import { BorrowerSimulatorFeatureConfiguration } from '../../interface/mortgage-simulator-feature.interface';
import defaultFeatureConfiguration from './default-borrower-simulator.configuration';

const featureConfiguration: BorrowerSimulatorFeatureConfiguration = {
	...defaultFeatureConfiguration,
	// impossible conditions to temporarily turn off the eligibility check
	eligibilityConstraint: {
		maxAge: 200,
		maxDsti: 1000000.0,
		maxLtv: 1000000.0,
	},
};

export default featureConfiguration;
