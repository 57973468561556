@if (isRateLocked()) {
	<div class="panel__container">
		<div class="panel__title">
			<oper-client-fontawesome-icon [icon]="'faPenToSquare'" [fixedWidth]="true" />
			{{ 'ç.feature.mortgageSimulator.report.rateLocked.title' | translate }}
		</div>

		<div class="panel__content">
			{{ 'ç.feature.mortgageSimulator.report.rateLocked.description' | translate }}
		</div>

		<oper-client-button
			class="panel__action"
			[label]="'ç.feature.mortgageSimulator.dashboard.simulations.addNew' | translate"
			[priority]="'tertiary'"
			[icon]="'faPlus'"
			[iconPlacement]="'left'"
			[fullWidth]="true"
			(clicked)="newSimulationClick.emit()"
		/>
	</div>
}

<h3 class="title">{{ 'ç.feature.mortgageSimulator.report.overview' | translate }}</h3>
<div class="overview">
	<oper-client-stacked-barchart [items]="items()" [isStacked]="false" [isToolTipActive]="true" />
</div>

<ng-content></ng-content>
