<oper-client-self-service-header
	[isAuthenticated]="auth.isAuthenticated()"
	[isUserVerified]="userVerified$ | async"
	[isContactsFeatureShown]="contactsEnabled$ | async"
	[isDocumentsFeatureShown]="documentsEnabled$ | async"
	[isSimulatorFeatureShown]="simulatorEnabled$ | async"
	[isSimulatorNewVersionEnabled]="simulatorNewVersionEnabled$ | async"
	[isLanguageSelectorShown]="showLanguageSelector$ | async"
	[shouldShowStartUploadingDocumentsMenuItemTooltip]="shouldShowStartUploadingDocumentsMenuItemTooltip$ | async"
	[isHamburgerMenuShown]="$isHamburgerMenuShown()"
	[isLanguageSelectorExpanded]="$isLangageSelectorExpanded()"
	[languages]="languages$ | async"
	[currentLanguage]="$currentLanguage()"
	(openLanguageSelector)="$isLangageSelectorExpanded.set(true)"
	(closeLanguageSelector)="$isLangageSelectorExpanded.set(false)"
	(languageChange)="languageChanged$.next($event)"
	(logoutClick)="logout()"
	(hamburgerMenuClick)="toggleHamburgerMenu()"
	(menuItemClick)="onMenuItemClick()"
/>

<main [dir]="localeDirection$ | async" [ngClass]="{ backdrop: $isHamburgerMenuShown() }">
	<router-outlet />
	<oper-client-links />
</main>

<oper-client-deployment-announcement [shown]="newDeploymentDetected$ | async" />
