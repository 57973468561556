import { removeUndefinedValues } from '@oper-client/shared/util-object';
import { getLoanPurposesFromDto } from '../dto/borrower-simulation.dto';
import { ClientDto } from '../dto/client.dto';
import { LoanApplicationDto } from '../dto/loan-application.dto';
import { Client } from '../models/client.model';
import { Income } from '../models/income.model';
import { creditLiabilityDefinitions, Liability, nonCreditLiabilityDefinitions } from '../models/liability.model';
import { LoanRequest } from '../models/loan-request.model';
import { MortgageRank, Ownership, Realty } from '../models/realty.model';
import { MapperStrategy } from '../utils/mapper.utils';

export class LoanApplicationDtoToLoanRequestMapper implements MapperStrategy<Partial<LoanApplicationDto>, Partial<LoanRequest>> {
	mapToRight(left: Partial<LoanApplicationDto>): Partial<LoanRequest> {
		return {
			notary: left.notary,
			ownFunds: left.ownFunds,
			purposes: getLoanPurposesFromDto(left),
			dateSubmission: left.dateSubmission,
			dateLoanRequestForm: left.dateLoanRequestForm,
		};
	}

	mapToLeft(right: Partial<LoanRequest>): Partial<LoanApplicationDto> {
		return {
			loanRequest: right,
			loanPurpose: right.purposes?.[0],
			ownFunds: right.ownFunds,
			notary: right.notary,
			dateSubmission: right.dateSubmission,
			dateLoanRequestForm: right.dateLoanRequestForm,
		};
	}
}

export class LoanApplicationDtoToClientsMapper implements MapperStrategy<Partial<LoanApplicationDto>, Partial<Client>[]> {
	mapToRight(dto: Partial<LoanApplicationDto>): Partial<Client>[] {
		const result: Partial<Client>[] = [];
		if (dto.mainBorrowerPersonalDetails) {
			result.push({
				...dto.clients?.[0],
				...dto.mainBorrowerPersonalDetails,
				id: dto.clients?.[0]?.id,
			});
		}

		if (dto.coBorrowerPersonalDetails) {
			result.push({
				...dto.clients?.[1],
				...dto.coBorrowerPersonalDetails,
				id: dto.clients?.[1]?.id,
			});
		}

		return result;
	}

	mapToLeft(entities: Partial<Client>[]): Partial<LoanApplicationDto> {
		const mainBorrower = entities[0];
		const coBorrower = entities[1];
		return {
			clients: entities as ClientDto[],
			mainBorrowerPersonalDetails: mainBorrower,
			coBorrowerPersonalDetails: coBorrower,
		};
	}
}

export class LoanApplicationDtoToLiabilitiesMapper implements MapperStrategy<Partial<LoanApplicationDto>, Partial<Liability>[]> {
	constructor(private readonly leftState?: Partial<LoanApplicationDto>) {}

	mapToRight(left: Partial<LoanApplicationDto>): Partial<Liability>[] {
		const result: Partial<Liability>[] = [];

		if (left.mainBorrowerLiabilities) {
			result.push(...left.mainBorrowerLiabilities);
		}

		if (left.coBorrowerLiabilities) {
			result.push(...left.coBorrowerLiabilities);
		}

		return result;
	}

	mapToLeft(liabilities: Partial<Liability>[]): Partial<LoanApplicationDto> {
		const creditLiabilities = [];
		const nonCreditLiabilities = [];
		const targetClientId = liabilities?.[0]?.client?.id;

		liabilities?.forEach((liability) => {
			if (creditLiabilityDefinitions.includes(liability?.liabilityType?.definition)) {
				creditLiabilities.push(liability);
			} else if (nonCreditLiabilityDefinitions.includes(liability?.liabilityType?.definition)) {
				nonCreditLiabilities.push(liability);
			}
		});

		const clients = this.leftState?.clients?.map((client) =>
			client.id === targetClientId ? ({ ...client, liabilities, creditLiabilities, nonCreditLiabilities } as ClientDto) : client
		);

		return {
			clients,
			mainBorrowerLiabilities: clients?.[0]?.liabilities,
			coBorrowerLiabilities: clients?.[1]?.liabilities,
		};
	}
}

export class LoanApplicationDtoToIncomesMapper implements MapperStrategy<Partial<LoanApplicationDto>, Partial<Income>[]> {
	constructor(private readonly leftState?: Partial<LoanApplicationDto>) {}

	mapToRight(left: Partial<LoanApplicationDto>): Partial<Income>[] {
		const result: Partial<Income>[] = [];

		if (left.mainBorrowerIncomes) {
			result.push(...left.mainBorrowerIncomes);
		}

		if (left.coBorrowerIncomes) {
			result.push(...left.coBorrowerIncomes);
		}

		return result;
	}

	mapToLeft(incomes: Partial<Income>[]): Partial<LoanApplicationDto> {
		const targetClientId = incomes?.find((income) => !!income?.client?.id)?.client?.id;
		const clients = this.leftState?.clients?.map((client) =>
			client.id === targetClientId ? ({ ...client, incomes } as ClientDto) : client
		);

		return {
			clients,
			mainBorrowerIncomes: clients?.[0]?.incomes,
			coBorrowerIncomes: clients?.[1]?.incomes,
		};
	}
}

export class LoanApplicationDtoToRealtyMapper implements MapperStrategy<Partial<LoanApplicationDto>, Partial<Realty>> {
	mapToRight(dto: Partial<LoanApplicationDto>): Partial<Realty> {
		return {
			...dto.mainRealty,
			address: dto.address,
			price: dto.realtyPrice?.toString(),
			priceLand: dto.priceLand?.toString(),
			priceBuilding: dto.priceBuilding,
			renovations: dto.renovations,
			epcAfterRenovations: dto.epcAfterRenovations,
			epcBeforeRenovations: dto.epcBeforeRenovations,
			epcDate: dto.epcDate,
			epcDateValidUntil: dto.epcDateValidUntil,
			usageTypes: dto.realtyUsageType ? [dto.realtyUsageType] : undefined,
			realtyType: dto.realtyType,
			purchaseSaleType: dto.purchaseSaleType,
			landPurchaseType: dto.landPurchaseType,
			architectFees: dto.architectFees,
			epcContractNumber: dto.epcContractNumber,
			venalValueAfter: dto.venalValueAfter,
			venalValueBefore: dto.venalValueBefore,
		};
	}

	mapToLeft(entity: Realty): Partial<LoanApplicationDto> {
		return {
			mainRealty: entity,
			realtyUsageType: entity?.usageTypes?.[0],
			realtyType: entity?.realtyType,
			address: entity?.address,
			priceBuilding: +entity?.priceBuilding,
			priceLand: +entity?.priceLand,
			realtyPrice: +entity?.price,
			renovations: entity?.renovations ?? [],
			epcAfterRenovations: entity?.epcAfterRenovations,
			epcBeforeRenovations: entity?.epcBeforeRenovations,
			epcDate: entity?.epcDate,
			epcDateValidUntil: entity?.epcDateValidUntil,
			region: entity?.address?.region,
			purchaseSaleType: entity?.purchaseSaleType,
			landPurchaseType: entity?.landPurchaseType,
			architectFees: entity?.architectFees,
			epcContractNumber: entity?.epcContractNumber,
			venalValueAfter: entity?.venalValueAfter,
			venalValueBefore: entity?.venalValueBefore,
		};
	}
}

export class LoanApplicationDtoToCollateralRealtyMapper implements MapperStrategy<Partial<LoanApplicationDto>, Partial<Realty>> {
	constructor(private readonly state?: Partial<LoanApplicationDto>) {}

	mapToRight(left: Partial<LoanApplicationDto>): Partial<Realty> {
		return {
			...left.collateralRealty,
			mortgageRanks: this.getUpdatedMortgageRanks(left),
			price: left.extraCollateral?.collateralAmount?.toString(),
			venalValueAfter: left.extraCollateral?.collateralAmount,
			realtyType: left.extraCollateral?.realtyType,
			usageTypes: left.extraCollateral?.realtyUsageType ? [left.extraCollateral.realtyUsageType] : undefined,
			id: left.extraCollateral?.id,
			epcContractNumber: left.extraCollateral?.epcContractNumber,
			epcBeforeRenovations: left.extraCollateral?.epcBeforeRenovations,
			epcDate: left.extraCollateral?.epcDate,
			address: left.extraCollateral?.address,
			livingPercentage: left.extraCollateral?.livingPercentage,
		};
	}

	mapToLeft(right: Partial<Realty>): Partial<LoanApplicationDto> {
		const mortgageRank = right?.mortgageRanks?.[0];
		return {
			collateralRealty: right as Realty,
			extraCollateral: {
				id: right?.id ?? mortgageRank?.id,
				creditProvider: mortgageRank?.creditProvider,
				originalLoanAmount: mortgageRank?.amount,
				collateralAmount: right?.venalValueAfter || +(right?.price ?? 0),
				realtyType: right?.realtyType,
				realtyUsageType: right?.usageTypes?.[0],
				epcContractNumber: right?.epcContractNumber,
				epcBeforeRenovations: right?.epcBeforeRenovations,
				epcDate: right?.epcDate,
				address: right?.address,
				livingPercentage: right?.livingPercentage,
			},
		};
	}

	private getUpdatedMortgageRanks(dto: Partial<LoanApplicationDto>): MortgageRank[] {
		const mortgageRanks = dto.collateralRealty?.mortgageRanks ?? this.state?.collateralRealty?.mortgageRanks;
		const firstCollateral = mortgageRanks?.[0];
		const changes: MortgageRank = {
			...firstCollateral,
			creditProvider: dto.extraCollateral?.creditProvider ?? firstCollateral?.creditProvider,
			amount: dto.extraCollateral?.originalLoanAmount ?? firstCollateral?.amount,
		};

		return changes?.id ? mortgageRanks?.map((rank) => (rank.id === changes.id ? changes : rank)) : undefined;
	}
}
export class LoanApplicationDtoCollateralOwnersMapper implements MapperStrategy<Partial<LoanApplicationDto>, Ownership[]> {
	constructor(private readonly state: Partial<LoanApplicationDto>) {}
	mapToRight(dto: Partial<LoanApplicationDto>): Ownership[] {
		return dto.owners;
	}
	mapToLeft(right: Ownership[]): Partial<LoanApplicationDto> {
		if (!right) return {};
		return {
			clients: this.updateClientsArray(this.state.clients, right as Ownership[]) as ClientDto[],
			collateralRealty: {
				...this.state.collateralRealty,
				clients: right,
			},
			extraCollateral: {
				...this.state.extraCollateral,
				owners: right.flatMap((ownership) => removeUndefinedValues(ownership.client)),
			},
		};
	}

	updateClientsArray(originalCLientsArray: Partial<Client>[], owners: Ownership[]): Partial<Client>[] {
		const clients = [...originalCLientsArray];
		owners.forEach((owner) => {
			const clientIndex = clients.findIndex((client) => client.id === owner.client.id);

			if (clientIndex === -1) {
				clients.push(owner.client);
			} else {
				clients[clientIndex] = { ...clients[clientIndex], ...owner.client };
			}
		});
		return clients;
	}
}
