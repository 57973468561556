import { registerLocaleData, CommonModule } from '@angular/common';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeDECH from '@angular/common/locales/de-CH';
import localeNLBE from '@angular/common/locales/nl-BE';
import localeENUK from '@angular/common/locales/en-GB';
import localeDEAT from '@angular/common/locales/de-AT';
import localeDEDE from '@angular/common/locales/de';
import localFRBE from '@angular/common/locales/fr-BE';
import localFRCH from '@angular/common/locales/fr-CH';
import localeITCH from '@angular/common/locales/it-CH';
import localeHUHU from '@angular/common/locales/hu';
import localeARAE from '@angular/common/locales/ar-AE';
import localeARSA from '@angular/common/locales/ar-SA';
import localeCSCZ from '@angular/common/locales/cs';
import { ErrorHandler, NgModule, LOCALE_ID, Injector, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';

import {
	CUSTOMER_INSIGHTS_CONFIG,
	CustomerInsights,
	ENVIRONMENT,
	APP_INSIGHTS_CONFIG,
	APP_CONFIG,
	Configuration,
	CORE_RESOURCES_VERSION,
	CoreInformation,
	CORE_INFORMATION,
	DEFAULT_LOCALE,
	DEFINED_LANGUAGES,
	NUMBER_FORMAT_OPTIONS,
	AuthType,
	initializeKeycloak,
} from '@oper-client/shared/configuration';
import {
	API_SERVICE,
	TokenInterceptor,
	RefreshTokenInterceptor,
	AUTH_SERVICE,
	AuthenticationGuard,
	APPLICATION_FLOW_SERVICE,
	MORTGAGE_SIMULATOR_SERVICE,
	AuthRedirectionService,
	ConnectionLostInterceptor,
} from '@oper-client/shared/data-access';

import { DataAccessAppModule } from '@oper-client/shared/app/data-access-app';
import { DataAccessClientModule } from '@oper-client/shared/client/data-access-client';
import { DataAccessDocumentModule } from '@oper-client/shared/document/data-access-document';
import { DataAccessIamModule } from '@oper-client/shared/iam/data-access-iam';
import { DataAccessLoanRequestModule } from '@oper-client/shared/loan-request/data-access-loan-request';
import { DataAccessRealtyModule } from '@oper-client/shared/realty/data-access';
import { DataAccessResourceModule } from '@oper-client/shared/resources/data-access-resource';
import {
	DataAccessApplicationFlowModule,
	LOAN_APPLICATION_FACADE_SERVICE,
	LOAN_APPLICATION_FEATURE_CONFIGURATION,
	loanApplicationConfigurationFactory,
	LoanApplicationFacade,
} from '@oper-client/shared/application-flow/data-access-application-flow';
import { DataAccessThirdPartyModule } from '@oper-client/shared/third-party/data-access-third-party';
import {
	BorrowerSimulatorApplicationFlowFacade,
	DataAccessMortgageSimulatorModule,
	BORROWER_SIMULATOR_FEATURE_CONFIGURATION,
	BORROWER_SIMULATOR_FACADE_SERVICE,
	borrowerSimulatorConfigurationFactory,
} from '@oper-client/shared/mortgage-simulator/data-access-mortgage-simulator';

import { UiModule } from '@oper-client/ui';
import { SentryErrorHandler } from '@oper-client/shared/util-error-handling';
import { CaseStyleInterceptor, UtilFormattingModule } from '@oper-client/shared/util-formatting';
import { numberFormatFactory, tokenGetter } from '@oper-client/shared/util-bootstrap';
import { gtmMetaReducer } from '@oper-client/shared/tracking/gtm';
import { ApiV2Module, ApplicationFlowService, MortgageSimulatorService, AppointmentBookingService } from '@oper-client/shared/api-v2.0';
import { LocalStorageService } from '@oper-client/shared/util-client-storage';
import { Router } from '@angular/router';
import { LanguageService } from '@oper-client/shared/util-language';
import { FeatureFlagModule, FeatureFlagService } from '@oper-client/shared/util-feature-flag';
import { CustomTranslateHttpLoader } from '@oper-client/shared/translation';
import { DataAccessLinkModule } from '@oper-client/shared/link/data-access-link';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SelfServiceCommonModule } from '@oper-client/self-service/common';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';

registerLocaleData(localeNLBE, 'nl-BE');
registerLocaleData(localeDECH, 'de-CH');
registerLocaleData(localeENUK, 'en-UK');
registerLocaleData(localeDEAT, 'de-AT');
registerLocaleData(localeDEDE, 'de-DE');
registerLocaleData(localFRBE, 'fr-BE');
registerLocaleData(localFRCH, 'fr-CH');
registerLocaleData(localeITCH, 'it-CH');
registerLocaleData(localeHUHU, 'hu-HU');
registerLocaleData(localeARAE, 'ar-AE');
registerLocaleData(localeARSA, 'ar-SA');
registerLocaleData(localeCSCZ, 'cs-CZ');

@NgModule({
	declarations: [AppComponent],
	bootstrap: [AppComponent],
	imports: [
		/* Angular */
		CommonModule,
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		ReactiveFormsModule,
		FormsModule,
		/* translations config */
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: CustomTranslateHttpLoader,
				deps: [HttpClient, DEFAULT_LOCALE, DEFINED_LANGUAGES],
			},
			useDefaultLang: true,
			defaultLanguage: 'en-UK',
		}),
		/* Store configuration */
		StoreModule.forRoot({ routerReducer }, { metaReducers: [gtmMetaReducer] }),
		EffectsModule.forRoot([]),
		StoreDevtoolsModule.instrument({
			maxAge: 25, // Retains last 25 states
			logOnly: environment.production, // Restrict extension to log-only mode
			connectInZone: true,
		}),
		/* Store modules imported eagerly */
		DataAccessAppModule,
		DataAccessIamModule,
		DataAccessResourceModule,
		DataAccessClientModule,
		DataAccessDocumentModule,
		DataAccessLoanRequestModule,
		DataAccessRealtyModule,
		DataAccessThirdPartyModule,
		DataAccessApplicationFlowModule,
		DataAccessMortgageSimulatorModule,
		DataAccessLinkModule,
		/* Others */
		UiModule,
		SelfServiceCommonModule,
		FeatureFlagModule,
		UtilFormattingModule,
		StoreRouterConnectingModule.forRoot(),
		KeycloakAngularModule,
		JwtModule.forRoot({
			jwtOptionsProvider: {
				provide: JWT_OPTIONS,
				useFactory: (config: CustomerInsights, injector: Injector) => ({
					tokenGetter: () => {
						if (config.authType === AuthType.KEYCLOAK_SSO) {
							return injector.get(AUTH_SERVICE).getAccessToken();
						} else {
							return tokenGetter();
						}
					},
					whitelistedDomains: config.whitelistedDomains,
				}),
				deps: [CUSTOMER_INSIGHTS_CONFIG, Injector],
			},
		}),
		ApiV2Module.initializeWithConfiguredAuthService(),
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: initializeKeycloak,
			deps: [KeycloakService, CUSTOMER_INSIGHTS_CONFIG],
			multi: true,
		},
		/* Global providers */
		{ provide: ErrorHandler, useClass: SentryErrorHandler },
		{ provide: ENVIRONMENT, useValue: environment },
		{
			provide: APP_INSIGHTS_CONFIG,
			useFactory: (config: Configuration) => config.applicationInsights,
			deps: [APP_CONFIG],
		},
		{ provide: CUSTOMER_INSIGHTS_CONFIG, useFactory: (env: Configuration) => env.customerInsights, deps: [APP_CONFIG] },
		{ provide: LOCALE_ID, useFactory: (config: CustomerInsights) => config.locale, deps: [CUSTOMER_INSIGHTS_CONFIG] },
		{ provide: CORE_RESOURCES_VERSION, useFactory: (info: CoreInformation) => info.resources, deps: [CORE_INFORMATION] },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: CaseStyleInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ConnectionLostInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: RefreshTokenInterceptor,
			multi: true,
		},
		{
			provide: AuthenticationGuard,
			deps: [AUTH_SERVICE, Router, AuthRedirectionService],
			useClass: AuthenticationGuard,
		},
		{ provide: APPLICATION_FLOW_SERVICE, useClass: ApplicationFlowService, deps: [API_SERVICE] },
		{ provide: MORTGAGE_SIMULATOR_SERVICE, useClass: MortgageSimulatorService, deps: [API_SERVICE] },
		{
			provide: BORROWER_SIMULATOR_FEATURE_CONFIGURATION,
			useFactory: (config: Configuration) => borrowerSimulatorConfigurationFactory(config),
			deps: [APP_CONFIG],
		},
		{
			provide: LOAN_APPLICATION_FEATURE_CONFIGURATION,
			useFactory: (config: Configuration) => loanApplicationConfigurationFactory(config),
			deps: [APP_CONFIG],
		},
		{
			provide: BORROWER_SIMULATOR_FACADE_SERVICE,
			useClass: BorrowerSimulatorApplicationFlowFacade,
		},
		{
			provide: LOAN_APPLICATION_FACADE_SERVICE,
			useClass: LoanApplicationFacade,
		},
		{ provide: AppointmentBookingService, deps: [API_SERVICE, LanguageService, FeatureFlagService, LocalStorageService] },
		{
			provide: NUMBER_FORMAT_OPTIONS,
			useFactory: (config: CustomerInsights) => numberFormatFactory(config, '1.0-0'),
			deps: [CUSTOMER_INSIGHTS_CONFIG],
		},
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class AppModule {}
