<div class="title">
	<h3>{{ 'ç.question.totalProjectCost.label' | translate }}</h3>
	<h3>{{ costsTotal() | environmentCurrency }}</h3>
</div>
<section class="content">
	@for (costGroup of financialPlanCostGroups(); track $index) {
		@if (costGroup.costGroupType === defaultCostGroupType()) {
			@for (cost of costGroup.costs; track $index) {
				<div class="details">
					<p class="details__label">{{ cost.label | translate }}</p>
					<p class="details__value">{{ cost.value | environmentCurrency }}</p>
				</div>
			}
		} @else {
			<oper-client-collapsible [open]="false">
				<ng-template operClientCollapsibleHeader [showArrow]="false">
					<div class="details">
						<p class="details__label">
							<b>{{ costGroup.label | translate }}</b>
						</p>

						<p class="details__value">
							<b> {{ costGroup.totalCosts | environmentCurrency }} </b>
						</p>
					</div>
				</ng-template>

				<ng-template operClientCollapsibleContent>
					@for (cost of costGroup.costs; track $index) {
						<div class="details details--wrapped" [title]="cost.helpText | translate">
							<p class="details__label">{{ cost.label | translate }}</p>
							<p class="details__value">{{ cost.value | environmentCurrency }}</p>
						</div>
					}
				</ng-template>
			</oper-client-collapsible>
		}
	}
</section>
