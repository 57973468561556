<div class="barchart__title">{{ total() | environmentCurrency }}</div>
<div class="barchart__container" #container [class.spread]="!isStacked()">
	@for (item of itemsFormatted(); track $index) {
		@if (item.value > 0) {
			<div
				class="barchart__item"
				[style.width.%]="(item.value / total()) * 100"
				[style.backgroundColor]="item.color"
				(mouseenter)="manageTooltip($index, $event)"
				(mouseleave)="manageTooltip(-1, $event)"
				(mousemove)="trackMouse($event)"
			></div>
		}
	}
	<div class="tooltip__text" #toolTip [style.left.px]="tooltipPosition().x" [style.top.px]="-tooltipSize().height">
		{{ itemsFormatted()[indexOfHoveredItem()]?.label | translate }}
	</div>
</div>
<div class="barchart__legend" [class.spread]="!isStacked()">
	@for (item of itemsLegend(); track $index) {
		<div class="legend__item">
			<div class="item__label">
				<span class="label__color" [style.backgroundColor]="item.color"></span>
				<p class="label__text">{{ item.label | translate }}</p>
			</div>
			<div class="item__value">{{ item.value | environmentCurrency }}</div>
		</div>
	}
</div>
